import React, { useState, createContext, useEffect } from "react";
import Loader from "components/UI/Loader/Loader";
import { Modal, Button } from "react-bootstrap";
import AuthService from "services/AuthService";
import UserService from "services/UserService";

/**
 * <AuthProvider/> contine detaliile utilizatorului curent logat, altfel null.
 */

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [message, setMessage] = useState(null);
  const [show, setShow] = useState(null);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    AuthService.getAuthToken().subscribe(token => {
      // daca token-ul de autentificare nu este prezent,
      // utilizatorul curent este null
      if (!token) {
        setPending(false);
        setCurrentUser(null);
        return;
      }

      // altfel, se cer detaliile utilizatorului
      UserService.getProfile()
        // se seteaza datele utiliatorului curent
        .then(response => {
          setPending(false);
          setCurrentUser(response.data);
        })
        // in caz de esec, se delogheaza utilizatorul curent
        .catch(() => {
          setPending(false);
          setCurrentUser(null);
          AuthService.logout();
        });
    });
  }, []);

  // se randeaza starea de loading a aplicatiei daca
  // utilizatorul curent nu a fost verificat\incarcat
  if (pending) {
    return <Loader />;
  }

  // se randeaza aplicatia daca utilizatorul curent a fost verificat\incarcat
  return (
    <>
      <Modal
        animation={false}
        id={"modalAuthContext"}
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        backdrop={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Atentie</Modal.Title>
        </Modal.Header>

        <Modal.Body>{message}</Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              setShow(false);
            }}
            style={{ float: "right", width: 150 }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <AuthContext.Provider
        value={{
          currentUser,
          showAlert: message => {
            setShow(true);
            setMessage(message);
          },
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

export default AuthProvider;
