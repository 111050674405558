import React, { Suspense, lazy } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/global-style.scss";
import "assets/scss/style.scss";
import api from "./api/api";
import config from "./config/app";
import Layout from "components/Layout/Layout";
import ConfigProvider from "config/configContext";
import { Container } from "react-bootstrap";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import PrivateRoute from "components/Auth/PrivateRoute";
import AuthProvider from "context/authContext";
import Loader from "components/UI/Loader/Loader";

import RouteChangeTracker from "helpers/RouteChangeTracker";

// start - pentru FontAwesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
//import NewBreadcrumbs from "containers/Dashboard/NewBreadcrumbs";
//import Toolbar from "containers/Dashboard/Toolbar/Toolbar";
library.add(fas, far, fab);
// end - pentru FontAwesome
const Signup = lazy(() => import("./containers/Auth/Signup/Signup"));

const SetareParolaContNou = lazy(() =>
  import("./containers/Auth/Signup/SetareParolaContNou")
);
const UploadFisiereRegister = lazy(() =>
  import("./containers/Auth/Signup/UploadFisiereRegister")
);
const Patrimony = lazy(() =>
  import("containers/Dashboard/pages/Patrimony/Patrimony")
);
const NewPassword = lazy(() =>
  import("containers/Auth/ResetPassword/NewPassword")
);
const NewCards = lazy(() => import("containers/Dashboard/NewCards"));
const DocumentsCards = lazy(() =>
  import("containers/Dashboard/DocumentsCards")
);
//const Declarations = lazy(() => import("containers/Dashboard/pages/Declarations/Declarations"));
const NewDeclarations = lazy(() =>
  import("containers/Dashboard/pages/NewDeclarations")
);
const Certificates = lazy(() =>
  import("containers/Dashboard/pages/Certificates/Certificates")
);
const Profile = lazy(() => import("containers/Profile/Profile"));
const NewDocumentTypes = lazy(() =>
  import("containers/Dashboard/NewDocumentTypes")
);
const NewUserManagement = lazy(() =>
  import("containers/Dashboard/NewUserManagement")
);
const Documentatie = lazy(() => import("./Documentatie/Documentatie.jsx"));
const NewRoleManagement = lazy(() =>
  import("containers/Dashboard/NewRoleManagement")
);
const IssuedPapers = lazy(() =>
  import("containers/Dashboard/pages/IssuedPapers/IssuedPapers")
);
const Payments = lazy(() =>
  import("containers/Dashboard/pages/Payments/Payments")
);
const Balance = lazy(() =>
  import("containers/Dashboard/pages/Balance/Balance")
);
const ElectronicPayment = lazy(() =>
  import("containers/Dashboard/pages/ElectronicPayment/ElectronicPayment")
);
const Accesari = lazy(() => import("containers/Dashboard/pages/Accesari.jsx"));
const Istoric = lazy(() => import("containers/Dashboard/pages/Istoric.jsx"));
const Corespondenta = lazy(() =>
  import("containers/Dashboard/pages/Corespondenta.jsx")
);

/**
 * <App/> randeaza interfata aplicatiei.
 *
 * Se foloseste lazy loading pentru randarea "la cerere".
 *
 * <AuthProvider/> permite consumarea contextului pentru utilizatorul curent logat in aplicatie.
 *
 * <Layout/> randeaza layoutul interfetei aplicatiei.
 *
 * <PrivateRoute/> incarca <Dashboard/> numai daca utilizatorul este logat.
 *
 * <Dashboard/> contine modulele utilizatorului logat, iar
 * restul de Components contin paginile publice ale aplicatiei.
 */

const Auth = lazy(() => import("containers/Auth/Auth"));
const Terms = lazy(() => import("containers/Webpages/Terms"));
const Policy = lazy(() => import("containers/Webpages/Policy"));
const Faq = lazy(() => import("containers/Webpages/Faq"));
const ContNouError = lazy(() => import("containers/Dashboard/ContNouError"));
const ContNouOk = lazy(() => import("containers/Dashboard/ContNouOk"));
const Contact = lazy(() => import("containers/Webpages/Contact"));
const PayFine = lazy(() => import("containers/Webpages/PayFine"));
const Legislation = lazy(() =>
  import("containers/Webpages/Legislation/Legislation")
);
const VerifyCertificate = lazy(() =>
  import("containers/Webpages/VerifyCertificate")
);
const SiteMap = lazy(() => import("containers/Webpages/SiteMap"));
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbs: [],
      editorMode: false,
      alertMessage: "",
      alertShow: false,
    };
    this.refSwitch = React.createRef();
  }

  handleBreadcrumbs = (items, history) => {
    var br = [];
    var arr = history.split("/");
    for (let i = 1; i < arr.length; i++) {
      var obj = { name: arr[i] };
      br.push(obj);
    }
    br.forEach((x, index, arr) => {
      var adresa = "";
      arr.forEach((y, ind) => {
        if (index >= ind) {
          adresa += y.name;
        }
      });
      x.func = () => {
        history.push({ pathname: adresa });
      };
    });
    this.setState({ breadCrumbs: br });
  };

  showError = alertMessage => {
    this.setState({ alertShow: true, alertMessage }, () => {
      setTimeout(() => {
        this.setState({ alertShow: false });
      }, 10000);
    });
  };

  componentDidMount = () => {
    api.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        var messageToShow = "";
        if (error && error.response && error.response.data) {
          if (typeof error.response.data === "string") {
            messageToShow = error.response.data;
          } else if (typeof error.response.data === "object") {
            var errData = error.response.data;

            if (errData.length !== undefined) {
              //array
              for (let ind in errData) {
                var valueOfErr = errData[ind];
                messageToShow += valueOfErr;
              }
            } else {
              //object
              Object.values(errData).forEach(mesaj => {
                messageToShow += mesaj;
              });
            }
          }
        }
        this.showError(messageToShow !== "" ? messageToShow : error.message);
        return Promise.reject(error);
      }
    );
  };

  PageChange = location => {
    api.post("/log", location);
  };
  closeAlert = () => {
    this.setState({ alertShow: false, alertMessage: "" });
  };

  render() {
    return (
      <ConfigProvider>
        <AuthProvider>
          <BrowserRouter basename={config.API.BASE_URL}>
            <Layout
              alertShow={this.state.alertShow}
              alertMessage={this.state.alertMessage}
              closeAlert={this.closeAlert}
              logPageChange={this.PageChange}
            >
              <Container fluid>
                <Suspense fallback={<Loader />}>
                  <Switch ref={this.refSwitch}>
                    <PrivateRoute
                      path={["/dashboard", "/dashboard/:routeId"]}
                      component={NewCards}
                      routeId={60}
                      handleBreadcrumbs={this.handleBreadcrumbs}
                    />
                    <Route path={"/Newpassword"} component={NewPassword} />
                    <Route
                      path="/declarations/:routeId"
                      component={NewDeclarations}
                    />
                    <Route path="/auth" component={Auth} />
                    <Route path="/termeni-si-conditii" component={Terms} />
                    <Route
                      path="/protectia-datelor-personale"
                      component={Policy}
                    />
                    <Route path="/legislatie" component={Legislation} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/intrebari-frecvente" component={Faq} />
                    <Route path="/plata-amenzi" component={PayFine} />
                    <Route
                      path="/verificare-certificate"
                      component={VerifyCertificate}
                    />
                    <Route path="/certificates" component={Certificates} />
                    <Route
                      path="/electronicpayment"
                      component={ElectronicPayment}
                    />
                    <Route path="/documentatie" component={Documentatie} />
                    <Route path="/balance" component={Balance} />
                    <Route path="/payments" component={Payments} />
                    <Route path="/issuedpapers" component={IssuedPapers} />
                    <Route
                      path="/patrimony"
                      render={props => (
                        <Patrimony {...props} isFullPatrimony={true} />
                      )}
                    />
                    <Route path="/documentsCards" component={DocumentsCards} />
                    <Route path="/contul-meu" component={Profile} />
                    <Route path="/auth/signup" component={Signup} />
                    <Route path="/documentTypes" component={NewDocumentTypes} />
                    <Route
                      path="/userManagement"
                      component={NewUserManagement}
                    />
                    <Route
                      path="/roleManagement"
                      component={NewRoleManagement}
                    />
                    <Route path="/accesari" component={Accesari} />
                    <Route path="/petitii" component={Corespondenta} />
                    <Route path="/contestatii" component={Corespondenta} />
                    <Route path="/corespondenta" component={Corespondenta} />
                    <Route path="/istoric" component={Istoric} />
                    <Route
                      path="/setareparolacontnou"
                      component={SetareParolaContNou}
                    />

                    <Route path="/cont-nou-ok" component={ContNouOk} />
                    <Route
                      path="/Uploadfisiereinregistrare"
                      component={UploadFisiereRegister}
                    />
                    <Route path="/cont-nou-error" component={ContNouError} />
                    <Route path="/harta-site" component={SiteMap} />
                    <Route path="*">
                      <Redirect to="/dashboard" />
                    </Route>
                  </Switch>

                  <RouteChangeTracker />
                </Suspense>
              </Container>
            </Layout>
          </BrowserRouter>
        </AuthProvider>
      </ConfigProvider>
    );
  }
}

export default App;
