// import AxiosCall from "../api/AxiosCall";
// import axios from "axios";

const baseURL = process.env.PUBLIC_URL ?? "";
const baseAPI =
  process.env.NODE_ENV === "production"
    ? baseURL + "/api"
    : "https://localhost/api";

const DEFAULT_API = {
  // base-endpoint general
  BASE_URL: baseURL,
  BASE_API: baseAPI,
  // Institution name
  INSTITUTION_NAME: "DVBL - Sector II",
  INSTITUTION_TITLE: "Direcția Venituri Buget Local Sector II",
  INSTITUTION_LOGO: baseURL + "/uat-logo.png",
  INSTITUTION_SITE: "https://www.impozitelocale2.ro/",
  COMPANY_LOGO: baseURL + "/atlas-sigla.png",
  email: "sotanga@ats.com.ro",
  telefon: " 0245 229 207 sau 0245 229 300 sau 0245 229 013",
  program:
    "De luni pană joi între orele 08.00 - 16.00, vineri între orele 08.00 - 14.00",
  //rutaInitiala: null,
};

const result = {
  API: DEFAULT_API,

  DATE_FORMAT: {
    // system date formats: api, storage, coding level
    SYSTEM_DATETIME: "YYYY-MM-DDTHH:mm:ss",

    // interface date formats: grid, text, reading level
    DATE: "DD.MM.YYYY",
    TIME: "hh:mm:ss",
    DATETIME: "DD.MM.YYYY hh:mm:ss",

    // datepicker date formats
    DATEPICKER_DATE: "dd.MM.yyyy",
    DATEPICKER_TIME: "HH:mm:ss",
    DATEPICKER_DATETIME: "dd.MM.yyyy HH:mm:ss",

    // documents date formats: pdf, text, printing level
    DOC_DATE: "DD.MM.YYYY",
    DOC_TIME: "hh:mm:ss",
    DOC_DATETIME: "DD.MM.YYYY hh:mm:ss",
  },

  // debounce
  INPUT_DEBOUNCE_WAIT: 1500,
};

export default result;
