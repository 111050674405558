import React from "react";
import _ from "lodash";

/**
 * Clasa UserData colecteaza detaliile unui utilizator, le formateaza si le randeaza.
 */

class UserData {
  static make(user) {
    return new UserData(user);
  }

  constructor(user) {
    const {
      rolUnic,
      numeSiPrenume,
      numeStrada,
      numarPostal,
      bloc,
      scara,
      etaj,
      apartament,
      adresaEmail,
      telefon,
      tipActIdentitate,
      seriaActIdentitate,
      numarActIdentitate,
      cui,
    } = user;

    this.user = user;
    this.rolUnic = rolUnic;
    this.numeSiPrenume = numeSiPrenume;
    this.numeStrada = numeStrada;
    this.numarPostal = numarPostal;
    this.bloc = bloc;
    this.scara = scara;
    this.etaj = etaj;
    this.apartament = apartament;
    this.adresa = this.list(
      [
        { value: numeStrada, name: "Str.", separator: "" },
        { value: numarPostal, name: "Nr.", separator: "" },
        { value: bloc, name: "Bl.", separator: "" },
        { value: scara, name: "Sc.", separator: "" },
        { value: etaj, name: "Et.", separator: "" },
        { value: apartament, name: "Ap.", separator: "" },
      ],
      ", "
    );
    this.adresaEmail = adresaEmail;
    this.telefon = telefon;
    this.tipActIdentitate = tipActIdentitate;
    this.numeTipActIdentitate = _.startCase(tipActIdentitate);
    this.seriaActIdentitate = seriaActIdentitate;
    this.numarActIdentitate = numarActIdentitate;
    this.actIdentitate = this.join([seriaActIdentitate, numarActIdentitate]);
    this.cui = cui;
  }

  join(values, glue = " ") {
    return values
      .filter(value => value !== null && value !== undefined && value !== "")
      .join(glue);
  }

  list(items, glue = <br />) {
    return items
      .filter(
        ({ value }) => value !== null && value !== undefined && value !== ""
      )
      .map(({ value, name = "", separator = ":" }, index) => {
        return (
          <React.Fragment key={index}>
            {index > 0 ? glue : ""}
            {name ? `${name}${separator} ${value}` : value}
          </React.Fragment>
        );
      });
  }
}

export default UserData;
