import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./NavigationItem.module.scss";

const navigationItem = props => {
  return (
    <NavLink
      onClick={() => {
        if (props.external) {
          window.open(props.link, "_blank");
        }
        props.setExpanded && props.setExpanded(false);
      }}
      className="nav-link font-semibold"
      exact={props.exact}
      to={props.link}
      activeClassName={`${classes.CustomActive} text-primary`}
    >
      {props.name}
    </NavLink>
  );
};

export default navigationItem;
