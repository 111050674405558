import AxiosCall from "./AxiosCall";
import config from "config/app";

/**
 * Intoarce o instanta Axios pentru endpoint-ul default "/api".
 */

const instance = AxiosCall.make({ baseURL: config.API.BASE_API })
  .withAuthToken()
  .whenAuthTokenExpiresLogout()
  .debug()
  .axios();

instance.interceptors.request.use(
  axiosConf => {
    axiosConf.baseURL = config.API.BASE_API;
    // console.log("x", axiosConf);
    // console.log(axiosConf.data, typeof axiosConf.data);
    // if (axiosConf.data) {
    //   var stringifuit = JSON.stringify(axiosConf.data);
    //   var faraDiacritice = stringifuit
    //     .normalize("NFKD")
    //     .replace(/[^\w\s.-_\/]/g, "");
    //   // var refacutStringifuitul = JSON.parse(faraDiacritice);
    //   console.log(stringifuit, faraDiacritice);
    //   //axiosConf.data = faraDiacritice;
    // }

    /** In dev, intercepts request and logs it into console for dev */
    return axiosConf;

    //Only get for demoUser
    /*
    var demoUser = localStorage.getItem("demoUser");
    var shouldReturn = true;
    if (demoUser) {
      if (config.method !== "get") {
        shouldReturn = false;
      }
    }
    if (shouldReturn) {
      return config;
    } else {
      throw new axios.Cancel("userDemo");
    }
    */
  },
  error => {
    console.error("Error API call", error);
    return Promise.reject(error);
  }
);

export default instance;
