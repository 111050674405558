import React from "react";
import classes from "./Loader.module.scss";
import { Spinner } from "react-bootstrap";

const Loader = (props) => {
  return (
    <div className={classes.Loader}>
      <div className="d-flex align-items-center">
        <Spinner className="mr-3" animation="grow" variant="primary" />
        <h4 className="mb-0 text-primary">{props.message ?? "Se incarca..."}</h4>
      </div>
    </div>
  );
};

export default Loader;
