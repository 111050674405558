import React from 'react';
import { withRouter } from 'react-router-dom';
import config from "../config/app";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

const RouteChangeTracker = ({ history }) => {
  ReactGA.initialize([
    { trackingId: config.API.GA_ID1 ? config.API.GA_ID1 : '', gaOptions: { name: 'tracker1' } },
    { trackingId: config.API.GA_ID2 ? config.API.GA_ID2 : '', gaOptions: { name: 'tracker2' } }
  ]);

  ReactPixel.init(config.API.FB_ID ? config.API.FB_ID : '');
  ReactPixel.pageView();

  history.listen((location, action) => {
    ReactGA.set({ page: location.pathname }, ['tracker1', 'tracker2']);
    ReactGA.pageview(location.pathname, ['tracker1', 'tracker2']);
  });

  return <div></div>;
}

export default withRouter(RouteChangeTracker);
