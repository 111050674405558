import axios from "axios";
import AuthService from "services/AuthService";
const https = require("https");

/**
 * AxiosCall este o clasa Factory care instantiaza un obiect Axios
 * pentru a face un request HTTP catre API.
 */

class AxiosCall {
  static make(config) {
    return new AxiosCall(config);
  }

  constructor(config) {
    config.httpsAgent = new https.Agent({
      rejectUnauthorized: false,
    });
    this.instance = axios.create(config);
  }

  // intoarce instanta Axios
  axios() {
    return this.instance;
  }

  // ataseaza token-ul de autentificare la request
  withAuthToken() {
    this.instance.interceptors.request.use(config => {
      const token = AuthService.getAuthTokenValue();
      config.headers.Authorization = token ? `Bearer ${token}` : "";

      return config;
    });

    return this;
  }

  // delogheaza utilizatorul in cazul in care token-ul de autentificare
  // a expirat, bazat pe statusul HTTP 401
  whenAuthTokenExpiresLogout() {
    this.instance.interceptors.response.use(
      response => response,
      error => {
        if (error && error.response && error.response.status === 401) {
          AuthService.logout();
        }

        return Promise.reject(error);
      }
    );

    return this;
  }

  // afiseaza in consola toate request-urile efectuate.
  debug() {
    // Treat the API response
    this.instance.interceptors.response.use(
      // Any status code that lie within the range of 2xx cause this function to trigger
      response => {
        return response;
      },

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      error => {
        console.error("axios[error]");
        console.error(error);

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error("axios[error.response]");
          console.error(error.response.data);
          console.error(error.response.status);
          console.error(error.response.headers);
          console.error("---------------");
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.error("axios[error.request]");
          console.error(error.request);
          console.error("---------------");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("axios[error.message]");
          console.error(error.message);
          console.error("---------------");
        }

        console.error("axios[error.config]");
        console.error(error.config);
        console.error("---------------");

        return Promise.reject(error);
      }
    );

    return this;
  }
}

export default AxiosCall;
