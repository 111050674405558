import React, { useState, useContext } from "react";
import classes from "./Header.module.scss";
import { Navbar, Container, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navigation from "./Navigation/Navigation";
import config from "../../../config/app";
import Avatar from "../../../containers/Dashboard/Toolbar/Avatar/Avatar";
import { AuthContext } from "context/authContext";

const Header = ({ alertShow, alertMessage, closeAlert }) => {
  const { currentUser } = useContext(AuthContext);
  const [expanded, setExpanded] = useState(false);
  const [pages] = useState([
    {
      name: "Acasa",
      link: "/dashboard",
      exact: false,
    },
    {
      name: "Legislație",
      link: config.API.INSTITUTION_SITE + "despre/",
      exact: false,
      external: true,
    },
    {
      name: "Întrebări",
      link: "/intrebari-frecvente",
      exact: false,
    },
    {
      name: "Contact",
      link: "/contact",
      exact: false,
    },
    {
      name: "Harta",
      link: "/harta-site",
      exact: false,
    },
  ]);
  return (
    <header className={classes.Header}>
      <Navbar
        className="shadow"
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="light"
        expanded={expanded}
      >
        <Container id={"containerTop"} fluid>
          <Link className="font-bold navbar-brand" to="/dashboard">
            <span
              style={{ fontWeight: "bold", fontSize: 36, color: "#00649b" }}
            >
              Atlas
            </span>
            <img
              style={{ width: 26, verticalAlign: "top" }}
              src={config.API.COMPANY_LOGO}
              alt="ATLAS"
            />
          </Link>
          {currentUser && <Avatar userInfo={currentUser} />}
          <Navbar.Toggle
            onClick={() => {
              setExpanded(!expanded);
            }}
            aria-controls="responsive-navbar-nav"
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Navigation setExpanded={setExpanded} links={pages} />
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Alert
        show={alertShow}
        variant="danger"
        dismissible={true}
        onClose={closeAlert}
      >
        {alertMessage}
      </Alert>
    </header>
  );
};

export default Header;
