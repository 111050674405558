import React from "react";
import { withRouter } from "react-router-dom";
import classes from "./Layout.module.scss";
import Header from "components/Layout/Header/Header";
import Footer from "components/Layout/Footer/Footer";

/**
 * <Layout/> randeaza header-ul, continutul si footer-ul aplicatiei.
 */

class Layout extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.location?.pathname !== prevProps.location?.pathname ||
      this.props.location?.search !== prevProps.location?.search
    ) {
      this.props.logPageChange(this.props.location);
    }
  }

  render = () => (
    <>
      <Header
        closeAlert={this.props.closeAlert}
        alertShow={this.props.alertShow}
        alertMessage={this.props.alertMessage}
      />
      <main className={classes.Main}>{this.props.children}</main>
      <Footer />
    </>
  );
}

export default withRouter(Layout);
