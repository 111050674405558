import React from "react";
import classes from "./Avatar.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import config from "../../../../config/app";
import UserData from "helpers/UserData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * <Avatar/> randeaza detaliile utilizatorului curent pentru header-ul Dashboard-ului.
 */

const avatar = ({ userInfo }) => {
  const user = UserData.make(userInfo);

  return (
    <div className={classes.Avatar}>
      <FontAwesomeIcon
        icon={["fas", "user-circle"]}
        style={{
          fontSize: 35,
          cursor: "pointer",
          placeSelf: "center",
          alignSelf: "center",
        }}
        color="#6c757d"
      />
      <div className={classes.Details}>
        <Container style={{ display: "block" }}>
          <Row>
            <Col className="small">
              {user.list([
                { value: user.numeSiPrenume },
                { value: user.rolUnic, name: "Rol Unic" },
                { value: user.cui, name: "CNP/CUI" },
                {
                  value: user.actIdentitate,
                  name: user.numeTipActIdentitate,
                },
              ])}
            </Col>
            <Col className="small">
              {user.adresa} <br />
              {user.list([
                { value: user.adresaEmail },
                { value: user.telefon },
              ])}
            </Col>
          </Row>
          {config.API.mesajNavigare && (
            <Row>
              <Col>
                <span
                  style={{ fontSize: 15, fontWeight: "bold", color: "black" }}
                >
                  {config.API.mesajNavigare}
                </span>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </div>
  );
};

export default avatar;
