import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "context/authContext";
import config from "../../config/app";

/**
 * <PrivateRoute/> randeaza componenta primita daca este logat un utilizator,
 * altfel redirectioneaza catre pagina de login.
 */

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  // se consuma contextul AuthContext
  const { currentUser } = useContext(AuthContext);

  const initialRouteId = config.API.rutaInitiala;
  const routeId =
    rest.location.pathname.includes("/dashboard/") ||
      rest.location.pathname.includes("/declarations/")
      ? rest.location.pathname.split("/")[
      rest.location.pathname.split("/").length - 1
      ]
      : initialRouteId;

  return (
    <Route
      {...rest}
      render={routerProps =>
        !!currentUser ? (
          <RouteComponent {...routerProps} {...rest} routeId={routeId} />
        ) : (
          <Redirect to="/auth/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
