import AxiosCall from "./AxiosCall";
import config from "config/app";

/**
 * Intoarce o instanta Axios pentru endpoint-ul utilizatorului "/utilizator".
 */

const instance = AxiosCall.make({
  baseURL: config.API.BASE_API + "/Utilizator",
})
  .withAuthToken()
  .whenAuthTokenExpiresLogout()
  // .debug()
  .axios();

instance.interceptors.request.use(axiosConf => {
  axiosConf.baseURL = config.API.BASE_API + "/Utilizator";
  /** In dev, intercepts request and logs it into console for dev */
  return axiosConf;
});

export default instance;
