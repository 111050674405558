import React, { useContext } from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import NavigationItem from "./NavigationItem/NavigationItem";
import { AuthContext } from "context/authContext";
import { Link, withRouter } from "react-router-dom";
import AuthService from "services/AuthService";
import config from "../../../../config/app";

/**
 * <Navigation/> randeaza o lista de link-uri, pagina de profil a utilizatorului
 * logat si efectueaza actiunea de logout.
 */

const Navigation = props => {
  const admin = localStorage.getItem("esteAdministrator")
    ? JSON.parse(localStorage.getItem("esteAdministrator").toLocaleLowerCase())
    : false;

  // se consuma contextul AuthContext
  const { currentUser } = useContext(AuthContext);
  const { history } = props;
  const { setExpanded } = props;

  const links = props.links.map((item, index) => (
    <NavigationItem
      setExpanded={setExpanded}
      key={index}
      link={item.link}
      name={item.name}
      exact={item.exact}
      external={item.external}
    />
  ));

  // delogheaza utilizatorul curent
  const handleLogout = () => {
    AuthService.logout();
    history.push("/auth/login");
    afterNavigate();
  };

  const afterNavigate = () => {
    setExpanded(false);
  };

  return (
    <Nav className="ml-auto">
      {/* <Nav.Link className="nav-link font-semibold" href="/portal">Prima pagina</Nav.Link> */}
      {links}
      {currentUser && (
        <>
          <NavDropdown
            className="font-semibold"
            title="Contul meu"
            id="collasible-nav-dropdown"
          >
            <NavDropdown.Item
              to="/contul-meu"
              onClick={afterNavigate}
              as={Link}
            >
              Profil
            </NavDropdown.Item>
            <NavDropdown.Divider />
            {config.API.afiseazaIstoric && (
              <>
                <NavDropdown.Item
                  to="/istoric"
                  onClick={afterNavigate}
                  as={Link}
                >
                  Istoric
                </NavDropdown.Item>
                <NavDropdown.Divider />
              </>
            )}
            {config.API.afiseazaIstoric && (
              <>
                <NavDropdown.Item
                  to="/accesari"
                  onClick={afterNavigate}
                  as={Link}
                >
                  Accesari
                </NavDropdown.Item>
                <NavDropdown.Divider />
              </>
            )}
            <NavDropdown.Item onClick={handleLogout}>
              Deconectare
            </NavDropdown.Item>
          </NavDropdown>

          {admin && (
            <NavDropdown
              className="font-semibold"
              title="Intretinere"
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item
                to="/documentTypes"
                onClick={afterNavigate}
                as={Link}
              >
                Tip documente
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                to="/userManagement"
                onClick={afterNavigate}
                as={Link}
              >
                Utilizatori
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                to="/roleManagement"
                onClick={afterNavigate}
                as={Link}
              >
                Roluri
              </NavDropdown.Item>
            </NavDropdown>
          )}
        </>
      )}
    </Nav>
  );
};

export default withRouter(Navigation);
