import user from "../api/user";

/**
 * Serviciul UserService este responsabil cu actiunile legate de Utilizator.
 */

class UserService {
  // intoarce detaliile utilizatorului curent
  getProfile = () => {
    return user.get("detalii");
  };

  changePersonalInfo = async ({
    tipActIdentitate,
    seriaActIdentitate,
    numarActIdentitate,
    emitentActIdentitate,
    dataExpirareActIdentitate,
  }) => {
    const config = { headers: { "Content-Type": "application/json" } };
    return await user.put(
      "personal-info",
      {
        tipActIdentitate: tipActIdentitate,
        seriaActIdentitate: seriaActIdentitate,
        numarActIdentitate: numarActIdentitate,
        emitentActIdentitate: emitentActIdentitate,
        dataExpirareActIdentitate: dataExpirareActIdentitate,
      },
      config
    );
  };

  // modifica parola utilizatorului curent
  changePassword = async ({
    parolaVeche,
    parolaNoua,
    confirmareParolaNoua,
  }) => {
    const config = { headers: { "Content-Type": "application/json" } };
    return await user.put(
      "parola",
      {
        parolaVeche: parolaVeche,
        parolaNoua: parolaNoua,
        confirmareParolaNoua: confirmareParolaNoua,
      },
      config
    );
  };

  // modifica adresa de email a utilizatorului curent
  changeEmail = async ({ adresaEmail }) => {
    const config = { headers: { "Content-Type": "application/json" } };
    return await user.put("email", JSON.stringify(adresaEmail), config);
  };

  // modifica numarul de telefon al utilizatorului curent
  changePhone = async ({ telefon }) => {
    const config = { headers: { "Content-Type": "application/json" } };
    return await user.put("phone", JSON.stringify(telefon), config);
  };
}

export default new UserService();
