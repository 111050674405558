import api from "../api/api";
import { BehaviorSubject } from "rxjs";
import jwt_decode from "jwt-decode";
import config from "../config/app";

/**
 * Serviciul AuthService este responsabil cu actiunile legate de autentificare.
 */

const authTokenSubject = new BehaviorSubject(localStorage.getItem("authToken"));

class AuthService {
  // inregistrare utilizator nou
  register = async data => {
    return await api.post(config.API.REGISTER_API_ENDPOINT, data);
  };

  // login utilizator
  login = async ({ type, username, password }) => {
    return await api
      .post("/auth/login", {
        tipPersoana: type,
        numeUtilizator: username,
        parolaUtilizator: password,
      })
      .then(response => {
        if (response.data) {
          this.setAuthToken(response.data.token, type);
        } else {
          this.removeAuthToken();
        }
      });
  };

  // logout utilizator
  logout = () => {
    this.removeAuthToken();
  };

  // determina daca utilizatorul este logat
  isLoggedIn = () => {
    return !!this.getAuthTokenValue();
  };

  // intoarce tokenul de autentificare
  getAuthToken = () => {
    return authTokenSubject.asObservable();
  };

  // seteaza tokenul de autentificare
  setAuthToken = (token, type) => {
    localStorage.clear();
    authTokenSubject.next(token);
    const decoded = jwt_decode(token);
    if (decoded["userRole"] && decoded["userRole"].length > 0) {
      localStorage.setItem(
        "esteAdministrator",
        decoded["userRole"].includes("Administrator")
      );
    } else {
      localStorage.setItem("esteAdministrator", false);
    }

    if (decoded["userUID"]) {
      localStorage.setItem("userUID", parseInt(decoded["userUID"]));
    }

    if (decoded["userId"]) {
      localStorage.setItem("userId", parseInt(decoded["userId"]));
    }
    if (decoded["userName"]) {
      localStorage.setItem("userName", decoded["userName"]);
    }
    if (decoded["allowOthers"]) {
      localStorage.setItem("allowOthers", decoded["allowOthers"]);
    }
    localStorage.setItem("authToken", token);
    var demoUser = decoded["userCUI"] === config.API.DEMO_USER;
    localStorage.removeItem("demoUser");
    if (demoUser) {
      localStorage.setItem("demoUser", demoUser.toString());
    }
    localStorage.setItem("ownerType", type === "PersoanaFizica" ? 1 : 2);
  };

  // elimina tokenul de autentificare
  removeAuthToken = () => {
    authTokenSubject.next(null);
    localStorage.clear();
  };

  // intoarce valoarea tokenului de autentificare
  getAuthTokenValue = () => {
    return authTokenSubject.value;
  };
}

export default new AuthService();
