import React, { useState, createContext, useEffect } from "react";
import Loader from "components/UI/Loader/Loader";
import axios from "axios";
import config from 'config/app';
import { Alert } from "react-bootstrap";

/*
 * <ConfigProvider/> contine configurarile
 */

export const ConfigContext = createContext(null);

const ConfigProvider = ({ children }) => {
  const [currentConfig, setCurrentConfig] = useState(null);
  const [pending, setPending] = useState(true);
  const [result, setResult] = useState({ hasError: false, errorMessage: '' });

  useEffect(() => {
    axios.get(config.API.BASE_URL + "/uat.json")
      .then(r => {
        delete r.data.BASE_URL;
        delete r.data.BASE_API;
        config.API = Object.assign(config.API, r.data);
        setCurrentConfig(config);
      })
      .catch(e => setResult({ hasError: true, errorMessage: e.toString() }))
      .finally(() => setPending(false));
  }, []);

  // se randeaza starea de loading a aplicatiei daca
  // utilizatorul curent nu a fost verificat\incarcat
  if (pending) {
    return <Loader />;
  }

  // se randeaza aplicatia daca utilizatorul curent a fost verificat\incarcat
  return (
    <ConfigContext.Provider value={{ currentConfig }}>
      {result.hasError ? <Alert message={result.errorMessage} /> : null}
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;