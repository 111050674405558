import React from "react";
import classes from "./Footer.module.scss";
import { Container, Row, Col } from "react-bootstrap";
// import badge from "assets/img/sigla.png";
import cards from "assets/img/carduri.png";
import { Link } from "react-router-dom";
import config from "../../../config/app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const footer = () => {
  return (
    <footer className={`${classes.Footer}`}>
      <Container fluid>
        <Row
          className="align-items-start">
          <Col sm={4} className="d-md-flex justify-content-end" style={{ textAlign: "center" }}>
            <img
              onClick={() => {
                window.open(config.API.INSTITUTION_SITE, "_blank");
              }}
              style={{ margin: "auto", textAlign: "center", cursor: "pointer" }}
              src={config.API.INSTITUTION_LOGO}
              alt={config.API.INSTITUTION_NAME}
            />
            {config.API.FooterDesc && <span style={{ margin: "auto", textAlign: "center", fontWeight: "bold", fontSize: 17, color: "#00649b" }}>{config.API.FooterDesc}</span>}
          </Col>
          <Col sm={4}>
            <ul style={{ padding: 0 }} className={classes.Pages}>
              {/* <li style={{ margin: "0 auto", textAlign: "center" }}>
                <Link to="/termeni-si-conditii">Termeni și condiții</Link>
              </li> */}
              <li style={{ margin: "0 auto", textAlign: "center" }}>
                <Link to="/protectia-datelor-personale">
                  <span style={{ color: "#00649b" }}>Protecția datelor personale</span>
                </Link>
              </li>

              <li style={{ margin: "0 auto", textAlign: "center" }}>
                <span>Copyright © Advanced Technology Systems</span>
              </li>
              <li style={{ textAlign: "center" }}>
                {config.API.INSTITUTION_FACEBOOK &&
                  config.API.INSTITUTION_FACEBOOK.length > 0 && (
                    <FontAwesomeIcon
                      onClick={() => {
                        window.open(config.API.INSTITUTION_FACEBOOK, "_blank");
                      }}
                      icon={["fab", "facebook"]}
                      style={{
                        fontSize: 28,
                        cursor: "pointer",
                        margin: "0px 4px",
                      }}
                      color="#00649b"
                    />
                  )}
                {config.API.INSTITUTION_INSTAGRAM &&
                  config.API.INSTITUTION_INSTAGRAM.length > 0 && (
                    <FontAwesomeIcon
                      onClick={() => {
                        window.open(config.API.INSTITUTION_INSTAGRAM, "_blank");
                      }}
                      icon={["fab", "instagram"]}
                      style={{
                        fontSize: 28,
                        cursor: "pointer",
                        margin: "0px 4px",
                      }}
                      color="pink"
                    />
                  )}
                {config.API.INSTITUTION_TWITTER &&
                  config.API.INSTITUTION_TWITTER.length > 0 && (
                    <FontAwesomeIcon
                      onClick={() => {
                        window.open(config.API.INSTITUTION_TWITTER, "_blank");
                      }}
                      icon={["fab", "twitter"]}
                      style={{
                        fontSize: 28,
                        cursor: "pointer",
                        margin: "0px 4px",
                      }}
                      color="skyblue"
                    />
                  )}
              </li>
            </ul>
          </Col>
          <Col sm={4} className="d-md-flex justify-content-end">
            <img
              style={{ margin: "0 auto", textAlign: "center" }}
              src={cards}
              alt="Carduri"
            />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default footer;
